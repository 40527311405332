<template lang="pug">
  .quick-start-page
    .header 
      .nio-jumbo-2.text-white Get started with Dataset Manager
      .nio-p-large.text-white Ingest data into the Narrative Data Streaming Platform.
      .graphic
    .steps-section
      h1.heading.nio-h1.text-primary-darker Setup your dataset for activation
      p.heading.nio-p.text-primary-dark Configure your file container, define columns or fields, and activate your dataset
      .steps
        .step(
          v-for="step of steps"
        )
          NioIconFramer(
            :icon-name="step.image"
            small
          )
          .data
            h4.nio-h4.text-primary-darker {{ step.heading }}
            p.description.nio-p.text-primary-dark {{ step.description }}

      .btn
        NioButton(@click="newDataset") Add new dataset
</template>

<script>

import { formatNumberVerbose } from '../../modules/helpers'

export default {
  data: () => ({
    stats: [
      {
        name: 'Source',
        value: 44,
        annotation: "Providers"
      },
      {
        name: 'Signals',
        value: 2860000,
        annotation: "Events"
      },
      {
        name: 'Mobile Identifiers',
        value: 386000000,
        annotation: "Mobile IDs"
      },
      {
        name: 'PC Identifiers',
        value: 434000000,
        annotation: "Cookie IDs"
      },
      {
        name: 'Identifiers',
        value: 621000000,
        annotation: "Hashed Emails"
      }
    ],
    steps: [
      {
        image: 'display-list',
        heading: 'Create the dataset',
        description: "Set the configuration and fields then activate your dataset."
      },
      {
        image: 'display-destinations',
        heading: 'Add data',
        description: "Manage ingestion settings and push data into your active dataset."
      },
      {
        image: 'display-payment',
        heading: 'Create data products',
        description: "Use activated datasets to create data streams in Seller Studio."
      }
    ]
  }),	
  methods: {
    newDataset() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new'
      },"*")
    },
    formatNumber(val) {
      return formatNumberVerbose(val)
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.quick-start-page
  display: flex
  flex-direction: column
  align-items: stretch
  .header
    padding: 64px
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    background: url('https://cdn.narrative.io/images/data-stream/apps/dataset-manager-quickstart.svg')
    background-repeat: no-repeat
    .graphic
      width: 596px
      height: 540px
      margin-bottom: -24px
      margin-top: -24px

    .nio-p-large
      margin-top: 8px
    .nio-button
      margin-top: 24px
      margin-bottom: 48px
  .steps-section
    padding: 64px 24px
    & > .heading, & > .description
      text-align: center
    & > .description
      margin-top: 8px
    .steps
      margin-top: 56px
      padding: 0px 20px
      display: flex
      flex-direction: column
      align-items: center
      .step
        width: 100%
        max-width: 640px
        display: flex
        justify-content: flex-start
        align-items: center
        flex-basis: 0
        text-align: center
        border: 1px $c-primary-lighter solid
        border-bottom: 0px
        padding: 1rem 1.5rem

        &:first-child
          border-radius: 12px 12px 0 0
        &:last-child
          border-radius: 0 0 12px 12px
          border-bottom: 1px solid $c-primary-lighter

        .data
          margin-left: 1rem
          display: flex
          flex-direction: column
          align-items: flex-start
          .description
            margin-top: 0.25rem
            margin-bottom: 0
    .btn
      display: flex
      justify-content: center
      margin-top: 3rem

</style>